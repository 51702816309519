import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Assurez-vous que 'auth' est correctement exporté depuis votre fichier firebase.js
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { app } from '../firebase'; // Vérifiez que 'app' est bien exporté depuis votre fichier firebase.js
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Star1 } from "iconsax-react";
import { useLocationData } from './GlobalContext';


const LocationComponent = () => {
  const [user, setUser] = useState(null);
  const [city, setCity] = useState(localStorage.getItem('cityName'));
  const [latitude, setLatitude] = useState(parseFloat(localStorage.getItem('latitude')));
  const [longitude, setLongitude] = useState(parseFloat(localStorage.getItem('longitude')));
  const [selectedModel, setSelectedModel] = useState(localStorage.getItem('defaultModel') || 'best_match'); // Charger le modèle par défaut
  const [generationTime, setGenerationTime] = useState(null);

  const [favoriteCities, setFavoriteCities] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const routeLocation = useLocation(); // Provenant de react-router-dom
  const { location } = useLocationData();




  const navigate = useNavigate();
  const { t } = useTranslation();

  const titleKey = routeLocation.pathname === '/tonightforecast' ? 'titletab1' : 'titletab3';


  useEffect(() => {
    const openMeteoData = JSON.parse(localStorage.getItem('openMeteoData'));

    if (openMeteoData && openMeteoData.generationtime_ms) {
      setGenerationTime(openMeteoData.generationtime_ms);
    }
  }, []);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        const favoritesDocRef = doc(getFirestore(), `users/${user.uid}/ouranos/favorites`);
        getDoc(favoritesDocRef)
          .then((docSnap) => {
            if (docSnap.exists()) {
              const favoritesData = docSnap.data();
              const favoritesList = Object.values(favoritesData); // Assurez-vous que les données sont structurées comme attendu
              setFavorites(favoritesList);
              const favoriteCitiesList = favoritesList.map((favorite) => favorite.city);
              setFavoriteCities(favoriteCitiesList);
            }
          })
          .catch((error) => {
            console.error("Erreur lors de la récupération des favoris :", error);
          });
      }
    });

    // Nettoyer l'abonnement à onAuthStateChanged lors du démontage du composant
    return () => unsubscribe();
  }, []);





  const handleStarClick = () => {
    const cityId = city.replace(/\s+/g, '_').toLowerCase(); // Utiliser le nom de la ville comme ID en supprimant les espaces
    const newFavorite = {
      city: city,
      latitude: latitude,
      longitude: longitude,
    };

    if (user) {
      const favoritesDocRef = doc(getFirestore(app), `users/${user.uid}/ouranos/favorites`);

      getDoc(favoritesDocRef).then((docSnap) => {
        const favoritesData = docSnap.exists() ? docSnap.data() : {};

        if (favoritesData[cityId]) {
          // Si la ville est déjà dans les favoris, la supprimer
          delete favoritesData[cityId];
        } else {
          // Sinon, ajouter la ville aux favoris
          favoritesData[cityId] = newFavorite;
        }

        // Mise à jour de Firestore
        setDoc(favoritesDocRef, favoritesData)
          .then(() => {
            console.log("Mise à jour réussie dans la base de données");
            // Mettre à jour l'état local après la mise à jour Firestore
            setFavorites(Object.values(favoritesData));
            setFavoriteCities(Object.values(favoritesData).map(fav => fav.city));
          })
          .catch((error) => {
            console.error("Erreur lors de la mise à jour dans la base de données :", error);
          });
      });
    } else {
      console.log("Non authentifié");
      navigate('/myprofile');
    }
  };


  // Vérifier si la ville est déjà définie comme favorite
  const isFavoriteCity = (city) => {
    return favoriteCities.includes(city);
  };


  const formattedTime = new Date(Date.now() + generationTime).toLocaleTimeString(localStorage.getItem('language') || 'en', { hour: '2-digit', minute: '2-digit' });


  return (
    <div className='flex md:justify-between flex-col md:flex-row text-left md:items-end mb-2' >

      <div className='flex gap-2' >


        {
          !location && location.city ? (
            <div className='ContainerSkeleton' style={{ width: '60px', height: '14px', marginTop: '30px' }}></div>
          ) : (

            <h1 className=' line-clamp-1	'>
              {t(titleKey)} - {location.city}
            </h1>
          )
        }


        {isFavoriteCity(city) ? (

          <button onClick={handleStarClick} variant='light' isIconOnly >
            <Star1 color='fill-amber-400' className='fill-amber-400' />
          </button>

        ) : (
          <button variant='light' isIconOnly onClick={handleStarClick}>
            <Star1 variant='Linear' />
          </button>

        )}
      </div>

      <p className='text-sm text-gray-400'>
        Last update : {formattedTime} - <font className="capitalize">{selectedModel}</font> model
      </p>

    </div>
  );
};
export default LocationComponent;