import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tickImage from '../assets/tick.svg';
import nopImage from '../assets/nop.svg';
import { calculateSeeingIndex } from './SeeingCalculator';
import { calculateSkyQualityIndex } from './calculateSkyQualityIndex';  // Import de la fonction externe
import CommonAreaChart from './CustomAreaChart';
import HourlyForecastComponent from './HourlyForecastComponent';
import { Star1 } from "iconsax-react";


const DailyComponent = ({ openMeteoData }) => {
  const { t } = useTranslation();
  const currentLanguage = navigator.language || 'en-US';
  const [selectedNight, setSelectedNight] = useState("");


  // Filtrer toutes les heures disponibles
  const now = new Date();

  // Format labels for night p
  const formatNightLabel = (nightStartDate) => {
    const options = { weekday: 'short', day: 'numeric' };
    const startDay = nightStartDate.toLocaleDateString(navigator.language, options);

    // Mettre en majuscule la première lettre du jour
    const startDayParts = startDay.split(' ');
    startDayParts[0] = startDayParts[0].charAt(0).toUpperCase() + startDayParts[0].slice(1);
    const formattedStartDay = startDayParts.join(' ');

    const nightEndDate = new Date(nightStartDate);
    nightEndDate.setDate(nightStartDate.getDate() + 1);
    const endDay = nightEndDate.toLocaleDateString(navigator.language, options);

    // Mettre en majuscule la première lettre du jour
    const endDayParts = endDay.split(' ');
    endDayParts[0] = endDayParts[0].charAt(0).toUpperCase() + endDayParts[0].slice(1);
    const formattedEndDay = endDayParts.join(' ');

    return `${formattedStartDay} - ${formattedEndDay}`;
  };



  const groupWeatherDataByNight = (weatherData) => {
    if (!weatherData || !weatherData.hourly || !weatherData.daily) return {};

    const { time, cloud_cover, cloud_cover_low, cloud_cover_mid, cloud_cover_high, relative_humidity_2m, wind_speed_10m, temperature_2m, dew_point_2m, precipitation_probability } = weatherData.hourly;
    const { sunset, sunrise } = weatherData.daily;

 

    const grouped = {};
    const today = new Date();
    let nightData = [];

    time.forEach((timestamp, index) => {
      const date = new Date(timestamp);
      const hour = date.getHours();

      const dayIndex = date.getDate() - 1;  // Alignement de l'index des jours

      // Start a new night from noon
      if (hour === 12) {
        if (nightData.length > 0 && new Date(nightData[0].timestamp) > today) {
          const nightLabel = formatNightLabel(nightData[0].timestamp);
          grouped[nightLabel] = {
            data: nightData,
            sunset: new Date(sunset[Object.keys(grouped).length]),
            sunrise: new Date(sunrise[Object.keys(grouped).length])
          };
        }
        nightData = [];
      }

      const seeingIndex = calculateSeeingIndex(cloud_cover[index], relative_humidity_2m[index], wind_speed_10m[index], temperature_2m[index], dew_point_2m[index]);
      const skyQualityIndex = calculateSkyQualityIndex(cloud_cover[index], relative_humidity_2m[index], wind_speed_10m[index], temperature_2m[index], dew_point_2m[index], seeingIndex);
    

      nightData.push({
        hour,
        timestamp: date,
        clouds: cloud_cover[index],
        cloudsLow: cloud_cover_low[index],
        cloudsMid: cloud_cover_mid[index],
        cloudsHigh: cloud_cover_high[index],
        humidity: relative_humidity_2m[index],
        windSpeed: Math.round(wind_speed_10m[index]),
        temperature: Math.round(temperature_2m[index]),
        dew_point_2m: Math.round(dew_point_2m[index]),
        precipitation_probability: Math.round(precipitation_probability[index]),
        rating: skyQualityIndex,
        seeing: seeingIndex, // Ajout de l'indice de seeing
        
      });

 
    });

    return grouped;
  };

  const groupedByNight = groupWeatherDataByNight(openMeteoData);

  if (!selectedNight && Object.keys(groupedByNight).length > 0) {
    setSelectedNight(Object.keys(groupedByNight)[0]);
  }




  const getBestNote = (data) => {
    if (!data || data.length === 0) return 0;
    const bestNote = Math.max(...data.map(item => item.rating));
    return Math.round(bestNote * 2) / 2;
  };


  const calculateAverageNote = (data) => {
    if (!data || data.length === 0) return 0;
    const totalNote = data.reduce((sum, item) => sum + item.rating, 0);
    const averageNote = totalNote / data.length;
    return Math.round(averageNote * 100) / 100; // Arrondi à deux décimales
  };



  const checkObservationConditions = (data) => {
    let consecutiveHours = 0;

    // Récupère les horaires de lever et coucher du soleil pour la nuit sélectionnée
    const sunsetTime = groupedByNight[selectedNight]?.sunset;
    const sunriseTime = groupedByNight[selectedNight]?.sunrise;

    if (!sunsetTime || !sunriseTime) return false;  // Si ces horaires sont manquants, on ne peut pas vérifier

    // Convertir les horaires en heures pour simplification
    const sunsetHour = sunsetTime.getHours();
    const sunriseHour = sunriseTime.getHours();

    for (let i = 0; i < data.length; i++) {
      const hourData = data[i];
      const hour = new Date(hourData.timestamp).getHours();  // Récupère l'heure à partir du timestamp

      // Vérifier si l'heure est pendant la nuit
      const isNight = hour >= sunsetHour || hour < sunriseHour;

      // Si c'est la nuit et la couverture nuageuse est inférieure à 30%
      if (isNight && hourData.clouds < 30) {
        consecutiveHours++;  // Si oui, on augmente le compteur
      } else {
        consecutiveHours = 0;  // Si la condition n'est pas remplie, on réinitialise le compteur
      }

      // Si on a trouvé 2 heures consécutives avec couverture nuageuse < 30% et pendant la nuit, l'observation est valide
      if (consecutiveHours >= 2) {
        return true;
      }
    }

    return false;
  };



  if (!openMeteoData) {
    return <div>{t('loading')}</div>;
  }


  return (
    <div>
      {/* Night selection buttons */}
      <div className='flex gap-3 overflow-auto '>
        {Object.keys(groupedByNight).map((night) => {
          const averageNote = calculateAverageNote(groupedByNight[night].data);
          const isObservationValid = checkObservationConditions(groupedByNight[night].data);
          const bestNote = getBestNote(groupedByNight[night].data);

          return (
            <button
              key={night}
              onClick={() => setSelectedNight(night)}
              className={`ContainerDay ${selectedNight === night ? 'bg-gray-800' : ''}`}
            >
              <p style={{ color: 'white', width: '80px' }} >{night}</p>
              <img
                src={isObservationValid ? tickImage : nopImage}
                alt={isObservationValid ? 'Valid' : 'Invalid'}
              />
              <div className='flex items-center gap-1' >
                <Star1 className='fill-gray-500 text-gray-500' size={16} />
                <p className='text-gray-500' >{bestNote}</p>
              </div>
            </button>
          );
        })}
      </div>


      <h1 className='text-2xl mt-6' >{selectedNight}</h1>

      <div className='md:grid md:grid-cols-2 gap-3 flex flex-col-reverse md:flex-row mt-3'>


        <HourlyForecastComponent
          data={groupedByNight[selectedNight]?.data || []}
          isFavorableCondition={(hourData) => {
            const hour = new Date(hourData.timestamp).getHours();

            // Récupérer les horaires de lever et coucher du soleil
            const sunsetTime = groupedByNight[selectedNight]?.sunset;
            const sunriseTime = groupedByNight[selectedNight]?.sunrise;

            if (!sunsetTime || !sunriseTime) return false;  // Si les horaires sont manquants, retourner false

            // Convertir en heures
            const sunsetHour = sunsetTime.getHours();
            const sunriseHour = sunriseTime.getHours();

            // Vérifier si l'heure est pendant la nuit (entre le coucher du soleil et le lever du soleil)
            const isNight = hour > sunsetHour || hour < sunriseHour;

            // Retourner la condition favorable (nuit et couverture nuageuse <= 30%)
            return isNight && hourData.clouds <= 30;
          }}
          sunrise={groupedByNight[selectedNight]?.sunrise}
          sunset={groupedByNight[selectedNight]?.sunset}
        />

        <CommonAreaChart
          data={groupedByNight[selectedNight]?.data}
          sunrise={groupedByNight[selectedNight]?.sunrise}  // Passer directement la valeur ici
          sunset={groupedByNight[selectedNight]?.sunset}  
        />


      </div>
    </div>
  );
};



export default DailyComponent;
