import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; // Importer les fonctions Firestore
import Logo from '../assets/logo-header.svg';
import { useTranslation } from 'react-i18next';
import { Button, Input, Alert } from "@nextui-org/react";
import { getFirebaseErrorMessage } from "./firebaseErrors"; // Ton fichier de gestion des erreurs

const SignUp = ({ onBackToLogin }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [username, setUsername] = useState("");
    const [alert, setAlert] = useState({ visible: false, message: "", color: "default" });
    const [loading, setLoading] = useState(false); // Déclarer l'état loading

    const showAlert = (message, color = "danger") => {
        setAlert({ visible: true, message, color });
        setTimeout(() => setAlert({ visible: false, message: "", color: "default" }), 5000);
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/\s+/g, ""); // Supprimer les espaces
        setter(value);
    };

    const signUp = async (e) => {
        e.preventDefault();

     
        if (!email || !password || !confirmPassword || !username) {
            showAlert(t('pleaseFillAllFields'), "danger");
            return;
        }

        if (password.length < 6) {
            showAlert(t('passwordTooShort'), "danger");
            return;
        }

        if (password !== confirmPassword) {
            showAlert(t('passwordsDoNotMatch'), "danger");
            return;
        }

        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Sauvegarder les informations utilisateur dans Firestore
            try {
                await setDoc(doc(getFirestore(), "users", user.uid), {
                    email: email,
                    username: username,
                });
                showAlert(t('accountCreatedSuccess'), "success"); // Alerte de succès

                onBackToLogin();
            } catch (firestoreError) {
                console.error("Error saving user information to Firestore", firestoreError);
                showAlert(t('errorSavingUserInfo'), "danger");
            }
        } catch (firebaseError) {
            const errorMessage = getFirebaseErrorMessage(firebaseError);
            showAlert(errorMessage, "danger");
        } finally {
            setLoading(false); // Arrêter le chargement après la fin du processus
        }
    };

    return (
        <div className="min-h-screen">
            <form className="flex flex-col gap-4 m-auto md:w-1/2" onSubmit={signUp}>
                <img style={{ width: '12em', margin: 'auto', paddingBlock: '2em' }} src={Logo} alt={t('logoAltText')} />
                <h1>{t('createAccount')}</h1>

                <Input
                    variant="faded"
                    size="lg"
                    label={t('usernameLabel')}
                    autoComplete="username"
                    type="text"
                    value={username}
                    placeholder={t('usernamePlaceholder')}
                    onChange={handleInputChange(setUsername)}
                />

                <Input
                    variant="faded"
                    size="lg"
                    label={t('emailLabel')}
                    autoComplete="email"
                    type="email"
                    placeholder={t('emailPlaceholder')}
                    value={email}
                    onChange={handleInputChange(setEmail)}
                />

                <Input
                    variant="faded"
                    size="lg"
                    label={t('passwordLabel')}
                    autoComplete="new-password"
                    type="password"
                    placeholder={t('passwordPlaceholder')}
                    value={password}
                    onChange={handleInputChange(setPassword)}
                />

                <Input
                    variant="faded"
                    size="lg"
                    label={t('confirmPasswordLabel')}
                    autoComplete="new-password"
                    type="password"
                    placeholder={t('confirmPasswordPlaceholder')}
                    value={confirmPassword}
                    onChange={handleInputChange(setConfirmPassword)}
                />

                {alert.visible && (
                    <Alert variant="flat" color={alert.color} title={alert.message} />
                )}

                <div className='flex flex-col gap-2' >
                    <Button  disabled={loading}  isLoading={loading}  size="lg" className="w-full mt-4" color="primary" type="submit">
                        {t('registerButton')}
                    </Button>

                    <p>{t('or')}</p>
                    {/* Bouton pour revenir à la connexion */}
                    <Button
                        size="lg"
                        className="w-full"
                        onPress={onBackToLogin}
                    >
                        {t('login')}
                    </Button>
                </div>

            </form>
        </div>
    );
};

export default SignUp;

