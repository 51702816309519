import React from 'react';
import { useTranslation } from 'react-i18next';
import { calculateSeeingIndex } from './SeeingCalculator';
import { calculateSkyQualityIndex } from './calculateSkyQualityIndex';  // Import de la fonction externe
import { CloudDrizzle, Clock, Cloud, Drop, Eye, Wind, Timer1, Diagram } from "iconsax-react";
import { TbTemperature } from "react-icons/tb";
import { MdDewPoint } from "react-icons/md";
import CommonAreaChart from './CustomAreaChart';
import { FiSunset, FiSunrise } from "react-icons/fi";
import HourlyForecastComponent from './HourlyForecastComponent';

const HourlyForecast = ({ openMeteoData }) => {
    const { t } = useTranslation();



    if (!openMeteoData) {
        return (
            <div style={{ marginTop: '20px' }}>
                <h2 style={{ marginTop: '30px', marginBottom: '10px' }}>{t('graph')}</h2>
                <div style={{ width: '100%', height: '300px', marginBlock: '10px' }} className='ContainerHourSkeleton'></div>
            </div>
        );
    }

    let sunsetTime = new Date(openMeteoData?.daily?.sunset[0]);
    let sunriseTime = new Date(openMeteoData?.daily?.sunrise[1]);



    // Ajouter 1 heure au coucher du soleil pour la nuit astronomique
    let astroNightStart = new Date(sunsetTime);
    astroNightStart.setHours(astroNightStart.getHours() + 2);

    // Soustraire 1 heure du lever du soleil pour la fin de la nuit astronomique
    let astroNightEnd = new Date(sunriseTime);
    astroNightEnd.setHours(astroNightEnd.getHours() - 2);


    // Filtrer toutes les heures disponibles
    const now = new Date();

    // Trouver la première heure de prévision après l'heure actuelle
    const maxHoursToDisplay = 24; // Nombre maximum d'heures à afficher à partir de maintenant


    if (openMeteoData.error) {
        console.error("API Error:", openMeteoData.reason);
        // Handle the error gracefully by returning an empty array or a default value
        return [];
    }


    const forecastData = openMeteoData.hourly?.time
        .map((time, index) => {
            const hourlyTime = new Date(time);
            if (hourlyTime.getTime() >= now.getTime() || hourlyTime.getHours() === now.getHours()) {
                const cloudCover = openMeteoData.hourly.cloud_cover?.[index] || 0;
                const windSpeed = openMeteoData.hourly.wind_speed_10m?.[index] || 0;
                const humidity = openMeteoData.hourly.relative_humidity_2m?.[index] || 0;
                const temperature = openMeteoData.hourly.temperature_2m?.[index] || null;
                const dew_point_2m = openMeteoData.hourly.dew_point_2m?.[index] || null;
                const seeing = calculateSeeingIndex(cloudCover, temperature, windSpeed, humidity, dew_point_2m)
                const skyQualityIndex = calculateSkyQualityIndex(cloudCover, humidity, windSpeed, temperature, dew_point_2m, seeing);

                return {
                    dateTime: hourlyTime,
                    hour: index === 0 ? 'Now' : hourlyTime.getHours(),
                    clouds: cloudCover,
                    precipitation_probability: openMeteoData.hourly.precipitation_probability?.[index] || 0,
                    clouds_low: openMeteoData.hourly.cloud_cover_low?.[index] || 0,
                    clouds_mid: openMeteoData.hourly.cloud_cover_mid?.[index] || 0,
                    clouds_high: openMeteoData.hourly.cloud_cover_high?.[index] || 0,
                    dew_point_2m: dew_point_2m,
                    temperature: temperature,
                    windSpeed: windSpeed,
                    windDirection: openMeteoData.hourly.wind_direction_10m?.[index] || null,
                    humidity: humidity,
                    isSunrise: hourlyTime.getHours() === sunriseTime.getHours() && hourlyTime.getDate() === sunriseTime.getDate(),
                    isSunset: hourlyTime.getHours() === sunsetTime.getHours() && hourlyTime.getDate() === sunsetTime.getDate(),
                    isAstroNightStart: hourlyTime.getHours() === astroNightStart.getHours() && hourlyTime.getDate() === astroNightStart.getDate(),
                    isAstroNightEnd: hourlyTime.getHours() === astroNightEnd.getHours() && hourlyTime.getDate() === astroNightEnd.getDate(),
                    rating: Math.round(skyQualityIndex),
                    seeing: seeing,
                    sunrise: openMeteoData.daily.sunrise[0],
                    sunset: openMeteoData.daily.sunset[0],
                };

            }
            return null;
        })
        .filter(data => data !== null)
        .slice(0, maxHoursToDisplay);




    return (
        <div>


            <div className='md:grid md:grid-cols-2 gap-3 flex flex-col-reverse md:flex-row mt-3'>
                <div>
                    <HourlyForecastComponent
                        data={forecastData}
                        isFavorableCondition={(hourlyData) =>
                            hourlyData.clouds <= 30 &&
                            (new Date(hourlyData.dateTime).getHours() <= new Date(hourlyData.sunrise).getHours() ||
                                new Date(hourlyData.dateTime).getHours() > new Date(hourlyData.sunset).getHours())
                        }
                        sunrise={sunriseTime}
                        sunset={sunsetTime}
                    />
                </div>

                <div>
                    <CommonAreaChart
                        data={forecastData}
                        sunrise={sunriseTime}
                        sunset={sunsetTime}
                    />
                </div>
            </div>


        </div >
    );
};

export default HourlyForecast;
